<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓储管理</el-breadcrumb-item>
      <el-breadcrumb-item>配件信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card aaass">
      <div class="toptitle">
        <el-button type="primary" @click="opentan()">添加信息</el-button>

        <div class="top_two">
          <el-input
            style="width: 300px"
            placeholder="请输入配件名称"
            v-model="sosuotext"
            class="input-with-select"
            @keyup.enter.native="sosuofun()"
            clearable
            @clear="handleEmptyno"
          >
            <el-button slot="append" icon="el-icon-search" @click="sosuofun()"></el-button>
          </el-input>
        </div>

        <div class="top_two">
          <el-input
            style="width: 300px"
            placeholder="请输入配件编号"
            v-model="goods_no"
            class="input-with-select"
            @keyup.enter.native="sosuogoods_no()"
            clearable
            @clear="handleEmptynobian"
          >
            <el-button slot="append" icon="el-icon-search" @click="sosuogoods_no()"></el-button>
          </el-input>
        </div>
      </div>

      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="date" sortable label="添加时间" width="150"></el-table-column>
        <el-table-column prop="goods_no" label="配件编号" width="150"></el-table-column>
        <el-table-column prop="goods_name" label="配件名称" width="150"></el-table-column>
        <el-table-column prop="type_title" label="配件类型" width="150"></el-table-column>
        <el-table-column prop="goods_desc" label="配件描述" width="150"></el-table-column>
        <el-table-column prop="goods_remark" label="配件备注" width="150" align="center"></el-table-column>
        <el-table-column prop="unit_name" label="配件单位" width="120" align="center"></el-table-column>
        <el-table-column label="配件状态" width="120px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-switch
              class="switch"
              :value="scope.row.goods_status"
              :active-value="1"
              :inactive-value="0"
              @change="ones(scope.row.id, $event)"
              active-text="启用"
              inactive-text="关闭"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row)"></el-button>
            <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <el-dialog title="配件信息" :visible.sync="dialogFormVisible" :before-close="handleClose" width="38%">
      <el-form :model="form" ref="form" label-width="100px" :rules="rules">
        <el-form-item label="配件名称" label-width="150px" prop="goods_name">
          <div class="el-select"><el-input v-model="form.goods_name" placeholder="请输入配件名称"></el-input></div>
        </el-form-item>
        <el-form-item label="配件类型" label-width="150px" prop="type">
          <el-cascader v-model="form.type" :options="leilist" :show-all-levels="false" @change="aaas" :props="propss"></el-cascader>
        </el-form-item>
        <el-form-item label="配件描述" label-width="150px" prop="goods_desc">
          <div class="el-select"><el-input v-model="form.goods_desc" placeholder="请输入配件描述"></el-input></div>
        </el-form-item>
        <el-form-item label="配件备注" label-width="150px">
          <div class="el-select"><el-input v-model="form.goods_remark" placeholder="请输入配件备注"></el-input></div>
        </el-form-item>
        <el-form-item label="配件单位" label-width="150px" prop="unit">
          <el-select v-model="form.unit" placeholder="未选择">
            <el-option v-for="item in danwei" :key="item.value" :label="item.name" :value="item.value" @click.native="danya(item.value)"></el-option>
          </el-select>
        </el-form-item>
        <!-- 动态增加表格单行 -->
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAddZJ">新增</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleDeleteZJ">删除</el-button>
          </el-col>
          <!-- {{ form }} -->
        </el-row>

        <el-table :data="form.suppliers" ref="zztable" @selection-change="handleSelectionChangezz" :row-class-name="zzTableRowClassName">
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column label="序号" type="index" width="50" />
          <el-table-column prop="name" label="供应商名称">
            <template slot-scope="scope">
              <el-select v-model="scope.row.supplier_id" placeholder="未选择">
                <el-option v-for="item in gonglist" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="供应商货物名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.goods_name"></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="供应商货物编码">
            <template slot-scope="scope">
              <el-input v-model="scope.row.goods_no"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quanxiao()">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="quanque()" @click="quanque(tantype)">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { Huowu, Xinpei, Getpeilist, Endpei, Jinpei, Qipei, Huoshu, Gongyingsou } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      goods_no: '',
      gonglist: [], // 供应商下拉数据
      aaa: '',
      sosuotext: '',
      tableData: [], // 列表数据
      tantype: 'add',
      leilist: [], // 货物类型
      dialogFormVisible: false,
      form: {
        goods_name: '', // 配件名称
        type: '', // 配件类型
        goods_desc: '', // 配件描述
        goods_remark: '', // 配件备注
        unit: '', // 单位
        suppliers: []
      },
      danwei: [
        { name: '请选择', value: '' },
        { name: '个', value: 1 },
        { name: '包', value: 2 },
        { name: '公斤', value: 3 },
        { name: '克', value: 4 },
        { name: '套', value: 5 }
      ],
      rules: {
        // 商品名称
        goods_name: [
          { required: true, message: '请输入配件名称', trigger: 'blur' },
          { min: 1, message: '长度最少1个字符', trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择配件类型', trigger: 'change' }],
        unit: [{ required: true, message: '请选择配件单位', trigger: 'change' }],
        goods_desc: [
          { required: true, message: '请输入配件描述', trigger: 'blur' },
          { min: 1, message: '长度最少1个字符', trigger: 'blur' }
        ]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      propss: {
        label: 'title',
        children: 'list',
        value: 'id'
      }
    }
  },
  mounted() {
    this.Huowulei()
    this.Getlists()
    this.Gongyings()
  },
  methods: {
    // name 清空
    handleEmptyno() {
      this.sosuotext = ''
      this.Getlists()
    },

    handleEmptynobian() {
      this.goods_no = ''
      this.Getlists()
    },

    sosuofun() {
      this.Getlists()
    },

    sosuogoods_no() {
      this.Getlists()
    },

    // 多选框选中数据
    handleSelectionChangezz(selection) {
      this.idszz = selection.map(item => item.fid)
    },

    //新增专家行
    handleAddZJ() {
      let obj = {}
      this.form.suppliers.push(obj)
    },

    //删除专家行
    handleDeleteZJ() {
      let arr = this.$refs.zztable.selection
      const arr1 = []
      for (let i = 0; i < arr.length; i++) {
        arr1.push(arr[i].index)
      }
      //数字数组排序，因为选择框是根据先选择的顺序存到checkedDetail里面，所以需要排序
      arr1.sort(function (a, b) {
        return a - b
      })
      for (let i = 0; i < arr1.length; i++) {
        this.form.suppliers.splice(arr1[i] - i - 1, 1)
      }
    },

    //添加行号
    zzTableRowClassName({ row, rowIndex }) {
      row.index = rowIndex + 1
    },

    aaas(e) {
      // console.log(e)
      let abc = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
      this.form.type = abc
    },
    // 添加
    opentan() {
      this.dialogFormVisible = true
    },
    ones(id, e) {
      console.log('当前id' + id, '当前状态是' + e)
      switch (e) {
        case 0:
          console.log('禁用')
          this.$confirm('是否禁用此配件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.Endlists(id)
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消禁用'
              })
            })
          break
        case 1:
          console.log('启用')
          this.$confirm('是否启用用此配件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.Qiyong(id)
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消启用'
              })
            })
          break

        default:
          break
      }
    },

    // 编辑
    editfun(ev) {
      console.log(ev)
      this.form.type = ev.last_type
      this.form = JSON.parse(JSON.stringify(ev))
      this.form.suppliers = ev.supplier_info
      this.tantype = 'edit'
      this.opentan()
    },

    // 关闭弹窗
    handleClose() {
      this.dialogFormVisible = false
      this.quanxiao()
    },

    // 商品类型选中
    chenlei(e) {
      //   console.log(e)
    },

    // 单位选中
    danya(e) {
      //   console.log(e)
    },

    // 取消
    quanxiao() {
      this.dialogFormVisible = false
      // this.$refs.form.resetFields()
      this.form.goods_desc = ''
      this.form.goods_remark = ''
      this.form.unit = ''
      this.tantype = 'add'
      this.form.id = ''
      this.form.goods_name = ''
      this.form.type = ''
      this.form.goods_no = ''
      this.form.type_name = ''
      this.form.goods_num = ''
      this.form.unit_name = ''
      this.form.goods_status = ''
      this.form.date = ''
      this.form.type_title = ''
      this.form.suppliers = []
      this.form.supplier_info = []
    },
    // 确定添加
    quanque(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['form'].validate(v => {
            if (v) {
              this.ClientInformations(this.form)
            }
          })
          break
        case 'edit':
          this.$refs['form'].validate(v => {
            if (v) {
              this.editcompanyfun(this.form)
            }
          })
          console.log(type)
          break
      }
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getlists()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getlists()
    },

    /////////// 请求区///////////

    // 仓库类型
    async Huowulei() {
      const { data } = await Huoshu()
      // console.log(data)
      this.leilist = data.data
    },

    // 新建配件
    async ClientInformations(v) {
      const { data } = await Xinpei(v)
      this.getanpostfun(data, '添加', this.quanxiao(), this.Getlists())
    },

    // 拉取配件列表
    async Getlists() {
      const { data } = await Getpeilist({ ...this.pageData, name: this.sosuotext, goods_no: this.goods_no })
      //   console.log(data)
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 修改配件信息
    async editcompanyfun(v) {
      const { data } = await Endpei(v)
      this.getanpostfun(data, '修改', this.quanxiao(), this.Getlists())
    },

    // 禁用配件
    async Endlists(id) {
      const { data } = await Jinpei({ id })
      this.getanpostfun(data, '禁用配件', this.Getlists())
    },
    // 启用配件
    async Qiyong(id) {
      const { data } = await Qipei({ id })
      this.getanpostfun(data, '启用配件', this.Getlists())
    },

    // 供应商
    async Gongyings() {
      const { data } = await Gongyingsou()
      // console.log(data)
      this.gonglist = data.data
    },

    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
/* switch按钮样式 */
.switch .el-switch__label {
  position: absolute;
  display: none;
  font-size: 12px !important;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch .el-switch__label--right span {
  margin-left: 5px;
}
/*关闭时文字位置设置*/
.switch .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch .el-switch__label--left span {
  margin-left: 18px;
}
/*显示文字*/
.switch .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 48px !important;
  margin: 0;
}
.el-switch__label .el-switch__label--right .is-active span {
  font-size: 12px;
}
.el-switch__label * {
  font-size: 12px !important;
}
.toptitle{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>